import './Shipment.scss'
import { useSelector } from 'react-redux'
import Button from '../../../../../UI/Button/Button'
import Upload from '../../../../../UI/Upload/Upload'
import fetch, { uploadTypes } from '../../../../../../fetch'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

const Shipment = ({ data, index }) => {
  const translation = useSelector((state) => state.translation)
  const token = useSelector((state) => state.token)
  const history = useHistory()
  const [error, setError] = useState(false)

  const received = async () => {
    setError(false)
    const { status } = await fetch('/admin/purchase-pipeline/shipment-received', { token, uuid: data.uuid })
    if (status) {
      history.push('/admin/purchase-pipeline/detail/' + data.uuid)
    } else {
      setError(true)
    }
  }

  const saveUploadedLabel = async (files) => {
    if (files[0]) {
      const { status } = await fetch('/admin/purchase-pipeline/shipment-label-uploaded', { token, uuid: data.uuid, file: files[0] })
      if (status) {
        history.push('/admin/purchase-pipeline/detail/' + data.uuid)
      } else {
        setError(true)
      }
    }
  }

  return (
    <div className="admin-entry-shipment">
      <div className="title">
        {index + 1}. {translation['admin.purchase-pipelinst-detail.item.shipment.title']}
      </div>
      {!data.shipmentMethod && <div className="label">{translation['admin.purchase-pipelinst-detail.item.shipment.not-selected']}</div>}
      {data.shipmentMethod && (
        <div>
          <div className="label">
            {translation['admin.purchase-pipelinst-detail.item.shipment.selected-prefix']}
            {translation[`purchase-pipeline.entry.type.shipment.methods.${data.shipmentMethod}`]}
          </div>
          {!data.shipmentLabelFile && (
            <div className="shipment-label-uploaded-wrapper">
              <Upload title={translation['admin.purchase-pipelinst-detail.item.shipment.upload-label']} uploadType={uploadTypes.SHIPMENT_LABEL} files={[]} uploadedCallback={saveUploadedLabel} />
            </div>
          )}
          {!data.shipmentReceived && data.shipmentLabelFile && (
            <div className="received-shipment-wrapper">
              <Button fullWidth={false} text={translation['admin.purchase-pipelinst-detail.item.shipment.mark-as-received']} onClick={received} />
            </div>
          )}
          {error && <div className="error">{translation['admin.purchase-pipelinst-detail.item.shipment.mark-as-received.error']}</div>}
        </div>
      )}
    </div>
  )
}

export default Shipment

import { useSelector } from 'react-redux'
import './Payment.scss'

const Payment = ({ data }) => {
  const translation = useSelector((state) => state.translation)

  return (
    <div className="entry-payment">
      {data.paymentFulfilled && <div className="label">{translation['purchase-pipeline.entry.type.payment.done']}</div>}
      {!data.paymentFulfilled && <div className="label">{translation['purchase-pipeline.entry.type.payment.pending']}</div>}
    </div>
  )
}

export default Payment

import { useSelector } from 'react-redux'
import './Completed.scss'
import DoneAllIcon from '@mui/icons-material/DoneAll'

const Completed = () => {
  const translation = useSelector((state) => state.translation)

  return (
    <div className="entry-completed">
      <div className="icon">
        <DoneAllIcon />
      </div>
      <div className="label">{translation['purchase-pipeline.entry.type.completed.message']}</div>
    </div>
  )
}

export default Completed

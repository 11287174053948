import Layout from '../Layout'
import './Admin.scss'
import logo from '../../../assets/image/logo.png'
import { projectName } from '../../../utility'
import { useDispatch, useSelector } from 'react-redux'
import Link from '../../UI/Link/Link'
import Cookies from 'universal-cookie'
import { setUser, setUserToken } from '../../../action/user'
import { useHistory } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import { useState } from 'react'

const Admin = (props) => {
  const translation = useSelector((state) => state.translation)
  const { overwriteNavigationComponent } = props
  const cookies = new Cookies()
  const dispatch = useDispatch()
  const history = useHistory()
  const [showNavigation, setShowNavigation] = useState(false)

  const toggleNavigation = () => setShowNavigation(!showNavigation)

  const logout = (e) => {
    e.preventDefault()
    cookies.remove('token', { path: '/' })
    dispatch(setUser(null))
    dispatch(setUserToken(null))
    history.push('/')
    toggleNavigation()
  }

  return (
    <Layout authRequired={'admin'} header={false} fullsize={true}>
      <div id="admin-layout">
        <div id="header">
          <div className="logo">
            <img src={logo} alt={projectName} />
            <div className="logo-label">{translation['layout-admin.logo-label']}</div>
          </div>
          <div className="mobile-nav-toggle visible-on-mobile" onClick={toggleNavigation}>
            <MenuIcon />
          </div>
          {!overwriteNavigationComponent && (
            <div className={`navigation ${showNavigation ? ' open' : ''}`}>
              <div className="item">
                <Link clickedCallback={toggleNavigation} href="/admin/purchase-pipeline/list/active" text={translation['layout-admin.navigation.purchase-pipeline.list.active']} />
              </div>
              <div className="item">
                <Link clickedCallback={toggleNavigation} href="/admin/purchase-pipeline/list/completed" text={translation['layout-admin.navigation.purchase-pipeline.list.completed']} />
              </div>
              <div className="item">
                <div className="logout" onClick={logout}>
                  {translation['layout-dashboard.logout']}
                </div>
              </div>
            </div>
          )}
          {overwriteNavigationComponent && overwriteNavigationComponent}
        </div>
        <div id="admin-content-wrapper" className={`${props.card ? 'card' : ''}`}>
          <div id="admin-content">{props.children}</div>
        </div>
      </div>
    </Layout>
  )
}

export default Admin

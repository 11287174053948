import { useSelector } from 'react-redux'
import './Offer.scss'
import Download from '../../../UI/Download/Download'
import { useState } from 'react'
import RadioGroup from '../../../UI/RadioGroup/RadioGroup'
import Button from '../../../UI/Button/Button'
import fetch from '../../../../fetch'
import { useHistory } from 'react-router-dom'

const Offer = ({ data }) => {
  const translation = useSelector((state) => state.translation)
  const [reply, setReply] = useState(null)
  const [iban, setIban] = useState('')
  const [error, setError] = useState(false)
  const history = useHistory()
  const options = [
    { key: true, value: translation['purchase-pipeline.entry.type.offer.reply.accept'] },
    { key: false, value: translation['purchase-pipeline.entry.type.offer.reply.decline'] },
  ]

  const submit = async () => {
    setError(false)
    const { status } = await fetch('/purchase-pipeline/offer-reply', { uuid: data.uuid, reply, iban })
    if (status) {
      history.push('/ankauf/' + data.uuid)
    } else {
      setError(true)
    }
  }

  if (data.offerReply !== undefined)
    return (
      <div className="entry-offer">
        {data.offerReply && <div className="label">{translation['purchase-pipeline.entry.type.offer.reply.received.accepted']}</div>}
        {!data.offerReply && <div className="label">{translation['purchase-pipeline.entry.type.offer.reply.received.declined']}</div>}
      </div>
    )
  return (
    <div className="entry-offer">
      <div className="download-wrapper">
        <Download title={translation['purchase-pipeline.entry.type.offer.download.label']} s3Key={data.offerFile.url} filename={data.offerFile.name} />
      </div>
      <div className="selection-wrapper">
        <RadioGroup name={''} value={reply} onChange={setReply} options={options} />
      </div>
      {reply === 'true' && (
        <div className="iban-wrapper">
          <input placeholder={translation['purchase-pipeline.entry.type.offer.reply.iban']} value={iban} onChange={(e) => setIban(e.target.value)} />
        </div>
      )}
      {(reply === 'false' || (reply === 'true' && iban.length > 0)) && (
        <div className="reply-cta-wrapper">
          {reply === true && <div></div>}
          <Button fullWidth={false} text={translation['purchase-pipeline.entry.type.offer.reply.send']} onClick={submit} />
          {error && <div className="error">{translation['purchase-pipeline.entry.type.offer.reply.send-error']}</div>}
        </div>
      )}
    </div>
  )
}

export default Offer

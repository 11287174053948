import './Offer.scss'
import { useSelector } from 'react-redux'
import Download from '../../../../../UI/Download/Download'

const Offer = ({ data, index }) => {
  const translation = useSelector((state) => state.translation)

  return (
    <div className="admin-entry-offer">
      <div className="title">
        {index + 1}. {translation['admin.purchase-pipelinst-detail.item.offer.title']}
      </div>
      {data.offerReply === true && <div className="label strong">{translation['admin.purchase-pipelinst-detail.item.offer.accepted']}</div>}
      {data.offerReply === false && <div className="label strong">{translation['admin.purchase-pipelinst-detail.item.offer.declined']}</div>}
      <div className="label">{translation['admin.purchase-pipelinst-detail.item.offer.label']}</div>
      <div className="download-cta-wrapper">
        <Download title={translation['admin.purchase-pipelinst-detail.item.offer.download']} s3Key={data.offerFile.url} filename={data.offerFile.name} />
      </div>
    </div>
  )
}

export default Offer

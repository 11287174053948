import './UnpackingVideo.scss'
import fetch, { uploadTypes } from '../../../../../fetch'
import Upload from '../../../../UI/Upload/Upload'
import Download from '../../../../UI/Download/Download'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

const UnpackingVideo = ({ uuid }) => {
  const translation = useSelector((state) => state.translation)
  const [video, setVideo] = useState(null)
  const token = useSelector((state) => state.token)
  const [error, setError] = useState(false)

  useEffect(() => {
    const getUnpackingVideo = async () => {
      const { status, video } = await fetch('/admin/purchase-pipeline/unpacking-video/get', { token, uuid })
      if (status) {
        setVideo(video)
      }
    }
    if (video === null && token && uuid) {
      getUnpackingVideo()
    }
  }, [video, token, uuid])

  const uploadedUnpackingVideo = async (files) => {
    if (files.length === 1) {
      const video = files[0]
      const { status } = await fetch('/admin/purchase-pipeline/unpacking-video/set', { token, uuid, video })
      if (status) {
        setVideo(video)
      } else {
        setError(true)
      }
    }
  }

  if (video === null) return ''
  return (
    <div className="unpacking-video">
      <div className="headline">{translation['admin.purchase-pipeline-detail.unpacking-video.headline']}</div>
      <div className="upload-wrapper">
        <Upload
          title={translation[video ? 'admin.purchase-pipeline-detail.unpacking-video.upload-cta-overwrite' : 'admin.purchase-pipeline-detail.unpacking-video.upload-cta']}
          uploadType={uploadTypes.UNPACKINGVIDEO}
          files={[]}
          uploadedCallback={uploadedUnpackingVideo}
        />
        {video && <Download title={translation['admin.purchase-pipeline-detail.unpacking-video.download-cta']} s3Key={video.url} filename={video.name} />}
      </div>
      {error && <div className="error">{translation['admin.purchase-pipeline-detail.unpacking-video.upload-failed']}</div>}
    </div>
  )
}

export default UnpackingVideo

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetch from '../../../fetch'
import './Settings.scss'
import Item from './Item/Item'
import { getUUID } from '../../../utility'
import AdminLayout from '../../Layout/Admin/Admin'
import CardHeader from '../../Layout/Dashboard/CardHeader/CardHeader'
import CardContent from '../../Layout/Dashboard/CardContent/CardContent'
import Create from './Create/Create'
import ExportImport from './ExportImport/ExportImport'

const Statistics = () => {
  const [settings, setSettings] = useState(null)
  const token = useSelector((state) => state.token)
  const translation = useSelector((state) => state.translation)
  const applicationOptions = [
    { value: 'frontend', label: translation['admin.settings.application.frontend'] },
    { value: 'backend', label: translation['admin.settings.application.backend'] },
  ]

  const loadSettings = async () => {
    const { status, settings } = await fetch('/admin/settings/get-all', { token })
    if (status && Array.isArray(settings)) {
      setSettings(settings)
    }
  }

  useEffect(() => {
    if (!settings && token) {
      loadSettings()
    }
  })

  return (
    <AdminLayout card={true}>
      <CardHeader headline={translation['layout-admin.navigation.settings.global']} />
      <CardContent>
        <div id="admin-settings">
          <Create loadSettings={loadSettings} applicationOptions={applicationOptions} />
          <ExportImport loadSettings={loadSettings} />
          <div className="list-headline">{translation['admin.settings.list-headline']}</div>
          <div className="list">{Array.isArray(settings) ? settings.map((setting) => <Item key={getUUID()} setting={setting} applicationOptions={applicationOptions} />) : ''}</div>
        </div>
      </CardContent>
    </AdminLayout>
  )
}

export default Statistics

export const set = (state, action) => {
  return { ...state, translations: action.payload }
}

export const setLang = (state, action) => {
  return { ...state, lang: action.payload }
}

export const setLangOptions = (state, action) => {
  return { ...state, langOptions: action.payload }
}

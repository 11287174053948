import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getUUID, shipmentMethods } from '../../../../utility'
import './Shipment.scss'
import { useState } from 'react'
import Button from '../../../UI/Button/Button'
import Download from '../../../UI/Download/Download'
import fetch from '../../../../fetch'

const Shipment = ({ data }) => {
  const translation = useSelector((state) => state.translation)
  const [selectedMethod, setSelectedMethod] = useState(null)
  const history = useHistory()
  const [error, setError] = useState(false)

  const confirm = async () => {
    const { status } = await fetch('/purchase-pipeline/shipment', { uuid: data.uuid, shipmentMethod: selectedMethod })
    if (status) {
      history.push('/ankauf/' + data.uuid)
    } else {
      setError(true)
    }
  }

  const coverLetterDownloaded = async () => {
    const { status } = await fetch('/purchase-pipeline/shipment-cover-letter-downloaded', { uuid: data.uuid, shipmentMethod: selectedMethod })
    if (status) {
      history.push('/ankauf/' + data.uuid)
    } else {
      setError(true)
    }
  }

  return (
    <div className="entry-shipment">
      {!data.shipmentMethod && (
        <div className="methods">
          {Object.keys(shipmentMethods).map((key) => {
            return (
              <div key={getUUID()} className={`method${selectedMethod === key ? ' selected' : ''}`} onClick={() => setSelectedMethod(key)}>
                <div className="name">{translation[`purchase-pipeline.entry.type.shipment.methods.${shipmentMethods[key]}`]}</div>
                <div className="description">{translation[`purchase-pipeline.entry.type.shipment.methods.${shipmentMethods[key]}.hint`]}</div>
              </div>
            )
          })}
        </div>
      )}
      {!data.shipmentMethod && selectedMethod && (
        <div className="confirm-cta">
          <Button onClick={confirm} text={translation['purchase-pipeline.entry.type.shipment.methods.confirm-cta']} fullWidth={false} />
        </div>
      )}
      {data.shipmentMethod && (
        <div>
          {!data.shipmentLabelFile && <div className="label">{translation['purchase-pipeline.entry.type.shipment.action-label']}</div>}
          {data.shipmentLabelFile && <div className="label">{translation['purchase-pipeline.entry.type.shipment.action-label-secondary']}</div>}
          {data.shipmentLabelFile && (
            <div className="shipment-label-download-wrapper">
              <Download title={translation['purchase-pipeline.entry.type.shipment.label.download-label']} s3Key={data.shipmentLabelFile.url} filename={data.shipmentLabelFile.name} />
            </div>
          )}
          {!data.shipmentLabelFile && <div className="label">{translation['purchase-pipeline.entry.type.shipment.cover-label.label']}</div>}
          {data.shipmentLabelFile && <div className="label">{translation['purchase-pipeline.entry.type.shipment.cover-label-secondary.label']}</div>}
          <div className="download-cover-label">
            <Download
              title={translation['purchase-pipeline.entry.type.shipment.cover-label.download-cta']}
              s3Key={'Begleitschreiben.pdf'}
              filename={'Begleitschreiben.pdf'}
              completedCallback={coverLetterDownloaded}
            />
          </div>
          {!data.shipmentLabelFile && <div className="hint">{translation['purchase-pipeline.entry.type.shipment.action-completed-hint']}</div>}
          {data.shipmentLabelFile && <div className="hint">{translation['purchase-pipeline.entry.type.shipment.action-completed-hint-secondary']}</div>}
        </div>
      )}
      {error && <div className="error">{translation['purchase-pipeline.entry.type.shipment.submit-error']}</div>}
    </div>
  )
}

export default Shipment

import './Review.scss'
import { useSelector } from 'react-redux'
import Upload from '../../../../../UI/Upload/Upload'
import fetch, { uploadTypes } from '../../../../../../fetch'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

const Review = ({ data, index }) => {
  const translation = useSelector((state) => state.translation)
  const token = useSelector((state) => state.token)
  const history = useHistory()
  const [error, setError] = useState(false)

  const saveOffer = async (files) => {
    if (files[0]) {
      const { status } = await fetch('/admin/purchase-pipeline/offer-upload', { token, uuid: data.uuid, file: files[0] })
      if (status) {
        history.push('/admin/purchase-pipeline/detail/' + data.uuid)
      } else {
        setError(true)
      }
    }
  }

  return (
    <div className="admin-entry-review">
      <div className="title">
        {index + 1}. {translation['admin.purchase-pipelinst-detail.item.review.title']}
      </div>
      {!data.reviewCompleted && (
        <div className="offer-upload">
          <Upload title={translation['admin.purchase-pipelinst-detail.item.review.upload-offer-label']} uploadType={uploadTypes.OFFER} files={[]} uploadedCallback={saveOffer} />
          {error && <div className="error">{translation['admin.purchase-pipelinst-detail.item.review.upload-offer.failed']}</div>}
        </div>
      )}
      {data.reviewCompleted && <div className="label">{translation['admin.purchase-pipelinst-detail.item.review.upload-offer.success']}</div>}
    </div>
  )
}

export default Review

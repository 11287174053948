import * as types from '../type/types'
import * as user from './user'
import * as settings from './settings'
import * as translation from './translations'
import de, { langKey } from './translationFiles/de'

const defaultState = {
  translation: de,
  token: null,
  user: null,
  currentLang: langKey,
}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_USER_TOKEN:
      return user.setToken(state, action)
    case types.SET_USER:
      return user.set(state, action)
    case types.SET_TRANSLATION:
      return translation.set(state, action)
    case types.SET_SETTINGS:
      return settings.set(state, action)
    default:
      return state
  }
}

export default reducer

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import fetch from '../../../fetch'
import AdminLayout from '../../Layout/Admin/Admin'
import './PurchasePipelineList.scss'
import { getPrettyDateTime, getUUID } from '../../../utility'

const PurchasePipelineList = () => {
  const params = useParams()
  const [list, setList] = useState(null)
  const token = useSelector((state) => state.token)
  const translation = useSelector((state) => state.translation)
  const [message, setMessage] = useState(null)
  const history = useHistory()

  useEffect(() => {
    const fetchList = async () => {
      const { status, list } = await fetch('/admin/purchase-pipeline/list', { token, status: params.status })
      if (status && Array.isArray(list)) {
        setList(list)
      } else {
        setMessage(translation['admin.purchase-pipelinst-list.loading.failed'])
      }
    }
    if (token) {
      fetchList()
    }
  }, [params, token, translation])

  const show = (uuid) => () => history.push('/admin/purchase-pipeline/detail/' + uuid)

  return (
    <AdminLayout>
      <div id="purchase-pipeline-list">
        {Array.isArray(list) && (
          <div className="list">
            <div className="headline">{translation['admin.purchase-pipelinst-list.headline']}</div>
            {list.map((item) => (
              <div className="item" key={getUUID()}>
                <div className="name">{`${item.firstname} ${item.lastname}`}</div>
                {params.status === 'active' ? (
                  <div className="date">
                    {translation['admin.purchase-pipeline-list.item.date-started.label']}
                    {getPrettyDateTime(new Date(item.startDate))}
                  </div>
                ) : (
                  <div className="date">
                    {translation['admin.purchase-pipeline-list.item.date-completed.label']}
                    {getPrettyDateTime(new Date(item.endDate))}
                  </div>
                )}
                {params.status === 'active' && (
                  <div className="status">
                    {translation['admin.purchase-pipelinst-list.item.latest-status.label']}
                    {translation[`purchase-pipeline.entry.type.${item.type}.title`]}
                  </div>
                )}
                <div className="detail-wrapper" onClick={show(item.uuid)}>
                  {translation['admin.purchase-pipelinst-list.item.view.label']}
                </div>
              </div>
            ))}
            {list.length === 0 && <div className="no-results">{translation[`admin.purchase-pipelinst-list.${params.status}.no-results`]}</div>}
          </div>
        )}
        {message && <div className="message">{message}</div>}
      </div>
    </AdminLayout>
  )
}

export default PurchasePipelineList

import { useSelector } from 'react-redux'
import './Review.scss'
import CheckIcon from '@mui/icons-material/Check'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'

const Review = ({ data }) => {
  const translation = useSelector((state) => state.translation)

  return (
    <div className="entry-review">
      {!data.reviewCompleted && (
        <div className="icon">
          <HourglassBottomIcon />
        </div>
      )}
      {!data.reviewCompleted && <div className="label">{translation['purchase-pipeline.entry.type.review.label.completed.false']}</div>}
      {data.reviewCompleted && (
        <div className="icon">
          <CheckIcon />
        </div>
      )}
      {data.reviewCompleted && <div className="label">{translation['purchase-pipeline.entry.type.review.label.completed.true']}</div>}
    </div>
  )
}

export default Review

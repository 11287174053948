import { useState } from 'react'
import { useSelector } from 'react-redux'
import './PersonalForm.scss'

const PersonalForm = ({ data, pushData }) => {
  const translation = useSelector((state) => state.translation)

  const [inputData, setInputData] = useState(data)

  const submit = (e) => {
    e.preventDefault()
    pushData(inputData)
  }

  const updateInput = (key) => (e) => setInputData({ ...inputData, [key]: e.target.value })

  return (
    <div id="personal-form">
      <div className="headline">{translation['personal-form.headline']}</div>
      <form onSubmit={submit}>
        <div className="attributes">
          <div className="input-wrapper">
            <input type="text" required={true} value={inputData.firstname || ''} onChange={updateInput('firstname')} placeholder={translation['personal-form.firstname']} />
          </div>
          <div className="input-wrapper">
            <input type="text" required={true} value={inputData.lastname || ''} onChange={updateInput('lastname')} placeholder={translation['personal-form.lastname']} />
          </div>
          <div className="input-wrapper">
            <input type="mail" required={true} value={inputData.mail || ''} onChange={updateInput('mail')} placeholder={translation['personal-form.mail']} />
          </div>
          <div className="input-wrapper">
            <input type="text" required={true} value={inputData.street || ''} onChange={updateInput('street')} placeholder={translation['personal-form.street']} />
            <input type="text" required={true} value={inputData.housenumber || ''} onChange={updateInput('housenumber')} placeholder={translation['personal-form.housenumber']} />
          </div>
          <div className="input-wrapper">
            <input type="text" required={true} value={inputData.postcode || ''} onChange={updateInput('postcode')} placeholder={translation['personal-form.postcode']} />
            <input type="text" required={true} value={inputData.city || ''} onChange={updateInput('city')} placeholder={translation['personal-form.city']} />
          </div>
          <div className="input-wrapper">
            <input type="text" value={inputData.addressNote || ''} onChange={updateInput('addressNote')} placeholder={translation['personal-form.addressNote']} />
          </div>
        </div>
        <div className="controls">
          <div className="submit-wrapper">
            <button type="submit">{translation['personal-form.submit']}</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default PersonalForm

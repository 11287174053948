import { useSelector } from 'react-redux'
import './Completed.scss'

const Completed = ({ data, index }) => {
  const translation = useSelector((state) => state.translation)
  return (
    <div className="admin-entry-completed">
      <div className="title">
        {index + 1}. {translation['admin.purchase-pipelinst-detail.item.completed.title']}
      </div>
    </div>
  )
}

export default Completed

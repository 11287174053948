import { useParams } from 'react-router-dom'
import './PurchasePipeline.scss'
import Layout from '../Layout/Layout'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Start from './EntryTypes/Start/Start'
import Shipment from './EntryTypes/Shipment/Shipment'
import Review from './EntryTypes/Review/Review'
import Offer from './EntryTypes/Offer/Offer'
import Payment from './EntryTypes/Payment/Payment'
import Completed from './EntryTypes/Completed/Completed'
import Wrapper from './EntryTypes/Wrapper/Wrapper'
import { getUUID, purchasePipelineEntryTypes } from '../../utility'
import fetch from '../../fetch'
import Container from 'react-bootstrap/esm/Container'
import Header from '../Layout/Header/Header'

const PurchasePipeline = () => {
  const params = useParams()
  const [entries, setEntries] = useState(null)
  const [message, setMessage] = useState(null)
  const translation = useSelector((state) => state.translation)
  const token = useSelector((state) => state.token)

  useEffect(() => {
    const fetchEntries = async () => {
      const uuid = params.uuid
      const { status, entries } = await fetch('/purchase-pipeline/entries', { uuid })
      if (status && entries) {
        setEntries(entries)
      } else {
        setMessage(translation['purchase-pipeline.loading.failed'])
      }
    }
    fetchEntries()
  }, [token, params, translation])

  const hasShipped = () => {
    return entries.find((e) => e.type === purchasePipelineEntryTypes.REVIEW)
  }

  return (
    <Layout>
      <div id="purchase-pipeline">
        <Header showBackButton={false} />
        <Container>
          <div className="card-content">
            {Array.isArray(entries) &&
              entries.map((entry, i) => {
                if (entry.type === purchasePipelineEntryTypes.START) {
                  return (
                    <Wrapper key={getUUID()} titleTranslation={`purchase-pipeline.entry.type.${entry.type}.title`} subtitleTranslation={`purchase-pipeline.entry.type.${entry.type}.subtitle`}>
                      <Start data={entry} />
                    </Wrapper>
                  )
                } else if (entry.type === purchasePipelineEntryTypes.SHIPMENT && !hasShipped()) {
                  return (
                    <Wrapper
                      key={getUUID()}
                      titleTranslation={`purchase-pipeline.entry.type.${entry.type}.title`}
                      subtitleTranslation={!entry.shipmentMethod && `purchase-pipeline.entry.type.${entry.type}.subtitle`}
                    >
                      <Shipment data={entry} />
                    </Wrapper>
                  )
                } else if (entry.type === purchasePipelineEntryTypes.REVIEW) {
                  return (
                    <Wrapper key={getUUID()} titleTranslation={`purchase-pipeline.entry.type.${entry.type}.title`}>
                      <Review data={entry} />
                    </Wrapper>
                  )
                } else if (entry.type === purchasePipelineEntryTypes.OFFER) {
                  return (
                    <Wrapper key={getUUID()} titleTranslation={`purchase-pipeline.entry.type.${entry.type}.title`}>
                      <Offer data={entry} />
                    </Wrapper>
                  )
                } else if (entry.type === purchasePipelineEntryTypes.PAYMENT) {
                  return (
                    <Wrapper key={getUUID()} titleTranslation={`purchase-pipeline.entry.type.${entry.type}.title`}>
                      <Payment data={entry} />
                    </Wrapper>
                  )
                } else if (entry.type === purchasePipelineEntryTypes.COMPLETED) {
                  return (
                    <Wrapper key={getUUID()} titleTranslation={`purchase-pipeline.entry.type.${entry.type}.title`}>
                      <Completed data={entry} />
                    </Wrapper>
                  )
                }
                return null
              })}
            {message && <div className="message">{message}</div>}
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default PurchasePipeline

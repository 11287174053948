import './Layout.scss'
import Footer from './Footer/Footer'
import Secure from './Secure/Secure'

const Layout = (props) => {
  const footer = props.footer !== undefined ? props.footer : true

  return (
    <div id="layout" className={`${props.fullsize === true ? 'fullsize' : ''}`}>
      <Secure authRequired={props.authRequired}>
        <div id="content-container">{props.children}</div>
        {footer && <Footer />}
      </Secure>
    </div>
  )
}

export default Layout

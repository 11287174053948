import './Item.scss'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import fetch from '../../../../fetch'
import { getUUID } from '../../../../utility'

const Item = ({ setting, applicationOptions }) => {
  const [application, setApplication] = useState(setting.application)
  const [group, setGroup] = useState(setting.group)
  const [value, setValue] = useState(setting.value)
  const [translationKey, setTranslationKey] = useState(setting.translationKey)
  const translation = useSelector((state) => state.translation)
  const token = useSelector((state) => state.token)
  const [message, setMessage] = useState(null)

  const update = async () => {
    setMessage(null)
    if (value.length > 0 && group.length > 0) {
      const { status } = await fetch('/admin/settings/upsert', { token, application, key: setting.key, group, value, translationKey })
      if (status) {
        setMessage(translation['admin.settings.update-message.success'])
      } else {
        setMessage(translation['admin.settings.update-message.failure'])
      }
    } else {
      setMessage(translation['admin.settings.update-message.failure'])
    }
  }

  return (
    <div className="settings-item">
      <div className="key">
        {translation['admin.settings.key']}: {setting.key}
      </div>
      <div className="property">
        <div className="label">{translation['admin.settings.application']}</div>
        <div className="selection">
          {applicationOptions.map((option) => {
            const uuid = getUUID()
            return (
              <div key={uuid} className="option">
                <input
                  checked={application === option.value}
                  onChange={(e) => setApplication(e.target.value)}
                  type="radio"
                  value={option.value}
                  name={`application-${uuid}`}
                  id={`application-${option.value}-${uuid}`}
                />{' '}
                <label htmlFor={`application-${option.value}-${uuid}`}>{option.label}</label>
              </div>
            )
          })}
        </div>
      </div>
      <div className="property">
        <div className="label">{translation['admin.settings.group']}</div>
        <input type="text" value={group} placeholder={translation['admin.settings.group']} onChange={(e) => setGroup(e.target.value)} />
      </div>
      <div className="property">
        <div className="label">{translation['admin.settings.value']}</div>
        <input type="text" value={value} placeholder={translation['admin.settings.value']} onChange={(e) => setValue(e.target.value)} />
      </div>
      <div className="property">
        <div className="label">{translation['admin.settings.translationkey']}</div>
        <input type="text" value={translationKey} placeholder={translation['admin.settings.translationkey']} onChange={(e) => setTranslationKey(e.target.value)} />
      </div>
      <div className="update" onClick={update}>
        {translation['admin.settings.update']}
      </div>
      {message && <div className="message">{message}</div>}
    </div>
  )
}

export default Item

import { Switch, Route } from 'react-router-dom'
import Users from './components/Admin/Users/Users'
import Settings from './components/Admin/Settings/Settings'
import PurchasePipelineList from './components/Admin/PurchasePipelineList/PurchasePipelineList'
import PurchasePipelineDetail from './components/Admin/PurchasePipelineList/Detail/Detail'

function AdminRouting({ match }) {
  return (
    <Switch>
      {/* PURCHASE PIPELINE LIST */}
      <Route exact={true} path={`${match.path}/purchase-pipeline/list/:status`} component={PurchasePipelineList} />
      {/* PURCHASE PIPELINE DETAIL */}
      <Route exact={true} path={`${match.path}/purchase-pipeline/detail/:uuid`} component={PurchasePipelineDetail} />
      {/* USERS */}
      <Route exact={true} path={`${match.path}/users`} component={Users} />
      {/* SETTINGS */}
      <Route exact={true} path={`${match.path}/settings`} component={Settings} />
    </Switch>
  )
}

export default AdminRouting

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import fetch from '../../../../fetch'
import AdminLayout from '../../../Layout/Admin/Admin'
import './Detail.scss'
import { getUUID, purchasePipelineEntryTypes } from '../../../../utility'
import Start from './EntryTypes/Start/Start'
import Shipment from './EntryTypes/Shipment/Shipment'
import Review from './EntryTypes/Review/Review'
import Offer from './EntryTypes/Offer/Offer'
import Payment from './EntryTypes/Payment/Payment'
import Completed from './EntryTypes/Completed/Completed'
import UnpackingVideo from './UnpackingVideo/UnpackingVideo'

const PurchasePipelineDetail = () => {
  const params = useParams()
  const [entries, setEntries] = useState(null)
  const token = useSelector((state) => state.token)
  const translation = useSelector((state) => state.translation)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    const fetchList = async () => {
      const { status, entries } = await fetch('/admin/purchase-pipeline/detail', { token, uuid: params.uuid })
      if (status && Array.isArray(entries)) {
        setEntries(entries)
      } else {
        setMessage(translation['admin.purchase-pipelinst-detail.loading.failed'])
      }
    }
    if (token) {
      fetchList()
    }
  }, [params, token, translation])

  const includesStep = (stepType) => !!entries.find((item) => item.type === stepType)

  return (
    <AdminLayout>
      <div id="purchase-pipeline-detail">
        {Array.isArray(entries) && (
          <>
            <div className="headline">{translation['admin.purchase-pipelinst-detail.headline']}</div>
            {includesStep(purchasePipelineEntryTypes.REVIEW) && <UnpackingVideo uuid={params.uuid} />}
            <div className="entries">
              {entries.map((item, i) => (
                <div className="item" key={getUUID()}>
                  {item.type === purchasePipelineEntryTypes.START && <Start index={i} data={item} />}
                  {item.type === purchasePipelineEntryTypes.SHIPMENT && <Shipment index={i} data={item} />}
                  {item.type === purchasePipelineEntryTypes.REVIEW && <Review index={i} data={item} />}
                  {item.type === purchasePipelineEntryTypes.OFFER && <Offer index={i} data={item} />}
                  {item.type === purchasePipelineEntryTypes.PAYMENT && <Payment index={i} data={item} />}
                  {item.type === purchasePipelineEntryTypes.COMPLETED && <Completed index={i} data={item} />}
                </div>
              ))}
            </div>
          </>
        )}
        {message && <div className="message">{message}</div>}
      </div>
    </AdminLayout>
  )
}

export default PurchasePipelineDetail

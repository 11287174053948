import './Payment.scss'
import { useSelector } from 'react-redux'
import Button from '../../../../../UI/Button/Button'
import fetch from '../../../../../../fetch'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

const Review = ({ data, index }) => {
  const translation = useSelector((state) => state.translation)
  const token = useSelector((state) => state.token)
  const history = useHistory()
  const [error, setError] = useState(false)

  const fulfilled = async () => {
    const { status } = await fetch('/admin/purchase-pipeline/payment-fulfilled', { token, uuid: data.uuid })
    if (status) {
      history.push('/admin/purchase-pipeline/detail/' + data.uuid)
    } else {
      setError(true)
    }
  }

  return (
    <div className="admin-entry-payment">
      <div className="title">
        {index + 1}. {translation['admin.purchase-pipelinst-detail.item.payment.title']}
      </div>
      <div className="iban">
        {translation['admin.purchase-pipelinst-detail.item.payment.iban-label']}
        {data.paymentIBAN}
      </div>
      {!data.paymentFulfilled && (
        <div className="fulfilled-cta-wrapper">
          <Button fullWidth={false} text={translation['admin.purchase-pipelinst-detail.item.payment.fulfilled-cta']} onClick={fulfilled} />
        </div>
      )}
      {data.paymentFulfilled && <div className="label">{translation['admin.purchase-pipelinst-detail.item.payment.fulfilled']}</div>}
      {error && <div className="error">{translation['admin.purchase-pipelinst-detail.item.payment.fulfilled-cta.failed']}</div>}
    </div>
  )
}

export default Review

import { useSelector } from 'react-redux'
import './Wrapper.scss'

const Wrapper = ({ titleTranslation, subtitleTranslation, children }) => {
  const translation = useSelector((state) => state.translation)
  return (
    <div className="entry-wrapper">
      <div className="header">
        <div className="title">{translation[titleTranslation]}</div>
        {subtitleTranslation && <div className="subtitle">{translation[subtitleTranslation]}</div>}
      </div>
      <div className="body">{children}</div>
    </div>
  )
}

export default Wrapper

import { useSelector } from 'react-redux'
import { getUUID, formatPrice } from '../../../../../../utility'
import './Start.scss'

const Start = ({ data, index }) => {
  const translation = useSelector((state) => state.translation)

  const hasAtLeastOneValue = (dataSet) =>
    dataSet.reduce((acc, set) => {
      if (acc === true) return true
      return Number.parseFloat(set.amountInGram) > 0
    }, false)

  return (
    <div className="admin-entry-start">
      <div className="title">
        {index + 1}. {translation['admin.purchase-pipelinst-detail.item.start.title']}
      </div>
      <div className="label">{translation['purchase-pipeline.entry.type.start.positions']}</div>
      {Object.keys(data.metalCalculatorData).map((key) => {
        if (!hasAtLeastOneValue(data.metalCalculatorData[key])) return ''
        return data.metalCalculatorData[key].map((variant) => {
          if (!variant.amountInGram) return null
          return (
            <div className="metal" key={getUUID()}>
              <div className="key">{translation[`metal-calculator.${key}.variant.${variant.token}`]}</div>
              <div className="value-wrapper">
                <div className="value" key={getUUID()}>
                  {`${variant.amountInGram}${translation['metal-calculator.gram.label-suffix']} (${formatPrice(variant.priceInCentsPerGram * variant.amountInGram)}${
                    translation['purchase-pipeline.entry.type.start.price.hint']
                  })`}
                </div>
              </div>
            </div>
          )
        })
      })}
      <div className="personal-data">
        <div className="entry">
          <div className="key">{translation['personal-form.firstname']}:</div>
          <div className="value">{data.firstname}</div>
        </div>
        <div className="entry">
          <div className="key">{translation['personal-form.lastname']}:</div>
          <div className="value">{data.lastname}</div>
        </div>
        <div className="entry">
          <div className="key">{translation['personal-form.mail']}:</div>
          <div className="value">{data.mail}</div>
        </div>
        <div className="entry">
          <div className="key">{translation['personal-form.street']}:</div>
          <div className="value">{data.street}</div>
        </div>
        <div className="entry">
          <div className="key">{translation['personal-form.housenumber']}:</div>
          <div className="value">{data.housenumber}</div>
        </div>
        <div className="entry">
          <div className="key">{translation['personal-form.postcode']}:</div>
          <div className="value">{data.postcode}</div>
        </div>
        <div className="entry">
          <div className="key">{translation['personal-form.city']}:</div>
          <div className="value">{data.city}</div>
        </div>
        <div className="entry">
          <div className="key">{translation['personal-form.addressNote']}:</div>
          <div className="value">{data.addressNote}</div>
        </div>
      </div>
    </div>
  )
}

export default Start

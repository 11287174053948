import { useSelector } from 'react-redux'
import { getUUID, formatPrice } from '../../../../utility'
import './Start.scss'

const Start = ({ data }) => {
  const translation = useSelector((state) => state.translation)

  console.log(data)

  const hasAtLeastOneValue = (dataSet) =>
    dataSet.reduce((acc, set) => {
      if (acc === true) return true
      return Number.parseFloat(set.amountInGram) > 0
    }, false)

  return (
    <div className="entry-start">
      {Object.keys(data.metalCalculatorData).map((key) => {
        if (!hasAtLeastOneValue(data.metalCalculatorData[key])) return ''
        return data.metalCalculatorData[key].map((variant) => {
          if (!variant.amountInGram) return null
          return (
            <div className="metal" key={getUUID()}>
              <div className="key">{translation[`metal-calculator.${key}.variant.${variant.token}`]}</div>
              <div className="amount">{`${variant.amountInGram}${translation['metal-calculator.gram.label-suffix']}`}</div>
              <div className="price" key={getUUID()}>
                {`${formatPrice(variant.priceInCentsPerGram * variant.amountInGram)}`}
              </div>
              <div className="hint">{`(${translation['purchase-pipeline.entry.type.start.price.hint']})`}</div>
            </div>
          )
        })
      })}
      <div className="bottom-hint">{translation['purchase-pipeline.entry.type.start.hint']}</div>
    </div>
  )
}

export default Start

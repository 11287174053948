import './Header.scss'
import { useHistory } from 'react-router-dom'
import headerLogo from '../../../assets/image/logo.png'
import { projectName } from '../../../utility'
import { useSelector } from 'react-redux'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

const Header = ({ showBackButton = true }) => {
  const history = useHistory()
  const translation = useSelector((state) => state.translation)

  const goHome = (e) => {
    e.preventDefault()
    window.scrollTo(0, 0)
    history.push('/')
  }

  return (
    <div id="header">
      <div className="logo" onClick={goHome}>
        <img src={headerLogo} alt={projectName} />
      </div>
      <div className="purchase-portal-label">{translation['header.purchase-portal-label']}</div>
      {showBackButton && (
        <div className="back-to-main-page">
          <ArrowBackIosIcon />
          <a href="https://bayern-gold24.de" target="_blank" rel="noreferrer">
            {translation['header.navigation.home']}
          </a>
        </div>
      )}
    </div>
  )
}

export default Header

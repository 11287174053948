import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setSettings } from '../../action/settings'
import fetch from '../../fetch'


const Settings = (props) => {
    const settings = useSelector(state => state.settings)
    const token = useSelector(state => state.token)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getSettings = async () => {
            const { status, settings } = await fetch('/settings/get', { token })
            if (status && settings) {
                dispatch(setSettings(settings))
                setLoading(false)
            }
        }

        if (!settings) {
            getSettings()
        } else {
            setLoading(false)
        }
    }, [dispatch, token, settings])

    if (loading) return <></>
    return <>{props.children}</>
}

export default Settings
import Container from 'react-bootstrap/Container'
import './Footer.scss'
import { useSelector } from 'react-redux'
import logo from '../../../assets/image/logo.png'
import Link from '../../UI/Link/Link'
import { projectName } from '../../../utility'

const Footer = () => {
  const translation = useSelector((state) => state.translation)

  return (
    <div id="footer">
      <div className="copyright">
        <Container>
          <div className="logo">
            <img src={logo} alt={projectName} />
          </div>
          <div>Copyright &copy; Bayern-Gold24 {new Date().getFullYear()}</div>
          <div>
            <Link href="https://bayern-gold24.de/impressum" text={translation['imprint']} newTab={true} />
          </div>
          <div>
            <Link href="https://bayern-gold24.de/agb" text={translation['gtc']} newTab={true} />
          </div>
          <div>
            <Link href="https://bayern-gold24.de/datenschutzbelehrung" text={translation['data-protection']} newTab={true} />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Footer

import { useEffect, useState } from 'react'
import fetch from '../../../fetch'
import Container from 'react-bootstrap/Container'
import Layout from '../../Layout/Layout'
import { useSelector } from 'react-redux'
import './Confirmation.scss'
import { useHistory, useParams } from 'react-router-dom'

const Confirm = () => {
  const params = useParams()
  const translation = useSelector((state) => state.translation)
  const [error, setError] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const confirm = async () => {
      const { status } = await fetch('/purchase-pipeline/confirm', { uuid: params.uuid })
      if (status) {
        history.push('/ankauf/' + params.uuid)
      } else {
        setError(true)
      }
    }
    confirm()
  }, [params, history])

  if (!error) <></>
  return (
    <Layout>
      <Container>
        <div id="purchase-pipeline-confirmation">
          {!error && <div className="message">{translation['purchase-pipeline.confirmation.loading']}</div>}
          {error && <div className="message">{translation['purchase-pipeline.confirmation.failed']}</div>}
        </div>
      </Container>
    </Layout>
  )
}

export default Confirm

import { useState } from 'react'
import './Download.scss'
import { useSelector } from 'react-redux'
import Button from '../Button/Button'
import { getS3DownloadURL } from '../../../fetch'

const Download = (props) => {
  const { title, s3Key, filename, fullWidth = false, completedCallback } = props

  const token = useSelector((state) => state.token)
  const translation = useSelector((state) => state.translation)
  const [error, setError] = useState(false)

  const propIsMissing = () => {
    const requiredProps = ['title', 's3Key']
    return requiredProps.reduce((acc, prop) => (acc === true ? true : props[prop] === undefined), false)
  }

  const startDownload = async () => {
    setError(false)
    const url = await getS3DownloadURL(token, s3Key, filename)
    if (url) {
      window.open(url, '_blank')
      if (typeof completedCallback === 'function') completedCallback()
    } else {
      setError(true)
    }
  }

  if (propIsMissing()) return <></>
  return (
    <div className="custom-download-handler">
      <Button fullWidth={fullWidth} text={title} onClick={startDownload} />
      {error && <div className="error">{translation['download.failed']}</div>}
    </div>
  )
}

export default Download

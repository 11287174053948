import { useEffect, useState } from 'react'
import './MetalCalculator.scss'
import fetch from '../../../fetch'
import { useSelector } from 'react-redux'
import { formatPrice, getPrettyDateTime, getUUID, minutesToMillis } from '../../../utility'
import goldOverlay from '../../../assets/image/metal-assets/gold_overlay.png'
import silverOverlay from '../../../assets/image/metal-assets/silver_overlay.png'
import platinOverlay from '../../../assets/image/metal-assets/platin_overlay.png'
import palladiumOverlay from '../../../assets/image/metal-assets/palladium_overlay.png'
import Dropdown from '../../UI/Dropdown/Dropdown'
import loader from '../../../assets/image/metal-assets/loader2.svg'

const MetalCalculator = ({ pushData, data }) => {
  const [metalList, setMetalList] = useState(null)
  const translation = useSelector((state) => state.translation)
  const [inputObject, setInputObject] = useState(data || {})
  const [selectedMetalType, setSelectedMetalType] = useState('gold')
  const [selectedVariantToken, setSelectedVariantToken] = useState('999')

  useEffect(() => {
    const fetchMetalData = async () => {
      const { status, metals } = await fetch('/metal/list')
      if (status && metals) {
        const inputObjectInit = metals.reduce((acc, metal) => {
          acc[metal.type] = metal.variants.reduce((acc, variant) => {
            acc[variant.token] = inputObject[metal.type] && inputObject[metal.type][variant.token] !== undefined ? inputObject[metal.type][variant.token] : 0
            return acc
          }, {})
          return acc
        }, {})
        setInputObject(inputObjectInit)
        setMetalList(metals)
        pushData(inputObjectInit)
      }
    }
    const fetchInterval = setInterval(fetchMetalData, minutesToMillis(10))
    fetchMetalData()
    return () => clearInterval(fetchInterval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const update = (type, token) => (value) => {
    value = value > 0 || value === '' ? value : 0
    const newInputObject = { ...inputObject, [type]: { ...inputObject[type], [token]: value } }
    setInputObject(newInputObject)
    pushData(newInputObject)
  }

  const hasAtLeastOneValue = (metalType) =>
    Object.keys(inputObject)
      .filter((typeKey) => typeKey === metalType || !metalType)
      .reduce((acc, typeKey) => {
        if (acc) return true
        return Object.keys(inputObject[typeKey]).reduce((acc, variantKey) => {
          if (acc) return true
          return !!inputObject[typeKey][variantKey]
        }, false)
      }, false)

  const getMetal = () => metalList.find((m) => m.type === selectedMetalType)

  const changeMetal = (type) => {
    setSelectedMetalType(type)
    const defaultVariant = metalList.find((m) => m.type === type).variants.filter((v) => v.token !== 'pure')[0].token
    setSelectedVariantToken(defaultVariant)
  }

  const getVariant = () => {
    const metal = getMetal()
    return metal.variants.find((v) => v.token === selectedVariantToken)
  }

  const getVariantByMetalTypeAndVariantToken = (metalType, variantToken) => {
    const metal = metalList.find((m) => m.type === metalType)
    return metal.variants.find((v) => v.token === variantToken)
  }

  const getOverlayImage = (metalType) => {
    switch (metalType) {
      case 'gold':
        return goldOverlay
      case 'silver':
        return silverOverlay
      case 'platinum':
        return platinOverlay
      case 'palladium':
        return palladiumOverlay
      default:
        break
    }
  }

  const removeEntry = (metalType, variantToken) => () => update(metalType, variantToken)(0)

  const getMetalTypeAndVariantOptionsForMobile = () =>
    metalList.reduce((acc, metal) => {
      acc.push(
        ...metal.variants
          .filter((v) => v.token !== 'pure')
          .map((v) => {
            return { key: `${metal.type}---${v.token}`, value: `${translation[`metal.${metal.type}`]} - ${translation[`metal-calculator.${metal.type}.variant.${v.token}`]}` }
          }),
      )
      return acc
    }, [])

  const updateMetalTypeAndVariantOnMobile = (key) => {
    const metalAndVariantSplit = key.split('---')
    setSelectedMetalType(metalAndVariantSplit[0])
    setSelectedVariantToken(metalAndVariantSplit[1])
  }

  if (!metalList)
    return (
      <div id="metal-calculator-data-loading">
        <div className="loader">
          <img src={loader} alt="loading..." />
        </div>
        <div className="label">{translation['metal-calculator.data-loading']}</div>
      </div>
    )
  return (
    <div id="metal-calculator">
      <div className="type-selector">
        <div className="label">{translation['metal-calculator.type-selector.label']}</div>
        {Array.isArray(metalList) && (
          <div className="list">
            {metalList.map((metal) => (
              <div key={getUUID()} className={`item ${metal.type}`} onClick={() => changeMetal(metal.type)}>
                <div className="overlay" style={{ backgroundImage: `url(${getOverlayImage(metal.type)})` }}></div>
                <div className="name">{translation[`metal.${metal.type}`]}</div>
                <div className={`stamp ${metal.type}`}>
                  {metal.type === 'gold' && 'AU'}
                  {metal.type === 'silver' && 'AG'}
                  {metal.type === 'platinum' && 'PT'}
                  {metal.type === 'palladium' && 'PD'}
                </div>
                <div className={`is-selected ${selectedMetalType === metal.type && metal.type}`}></div>
              </div>
            ))}
          </div>
        )}
      </div>
      {selectedMetalType && (
        <div className="fineness-selector">
          <div className="label">{translation['metal-calculator.fineness-selector.headline']}</div>
          <div className="list">
            {getMetal().variants.map((variant) => {
              if (variant.token === 'pure') return ''
              return (
                <div key={getUUID()} className="item" onClick={() => setSelectedVariantToken(variant.token)}>
                  <div className={`is-selected ${selectedVariantToken === variant.token && 'selected'}`}></div>
                  <div className="name">{translation[`metal-calculator.${selectedMetalType}.variant.${variant.token}`]}</div>
                </div>
              )
            })}
          </div>
        </div>
      )}
      <div className="mobile-selector">
        <Dropdown
          showEmptyOption={false}
          name={translation['metal-calculator.type-selector.label']}
          options={getMetalTypeAndVariantOptionsForMobile()}
          onChange={updateMetalTypeAndVariantOnMobile}
          value={`${selectedMetalType}---${selectedVariantToken}`}
        />
      </div>
      {selectedVariantToken && (
        <>
          <div className="amount-input">
            <div className="current-price">
              <div className="label">{translation['metal-calculator.amount-input.current-price.label']}</div>
              <div className="value">{formatPrice(getVariant().priceInCentsPerGram)}</div>
            </div>
            <div className="visible-on-mobile refreshed-data">{translation['metal-calculator.refreshed-on-label'] + getPrettyDateTime(new Date())}</div>
            <div className="input-wrapper">
              <div className="label">{translation['metal-calculator.input-wrapper.label']}</div>
              <div className="value">
                <input type="number" value={inputObject[selectedMetalType][selectedVariantToken]} onChange={(e) => update(selectedMetalType, selectedVariantToken)(e.target.value)} />
              </div>
            </div>
            <div className="result">
              <div className="label">{translation['metal-calculator.result.label']}</div>
              <div className="value">{formatPrice(inputObject[selectedMetalType][selectedVariantToken] * getVariant().priceInCentsPerGram)}</div>
            </div>
          </div>
          <div className="hidden-on-mobile refreshed-data">{translation['metal-calculator.refreshed-on-label'] + getPrettyDateTime(new Date())}</div>
        </>
      )}
      {hasAtLeastOneValue() && (
        <div className="summary">
          <div className="headline">{translation['meta-calculator.summary.headline']}</div>
          {Object.keys(inputObject).map((metalType) => {
            return (
              <div className="item" key={getUUID()}>
                {Object.keys(inputObject[metalType]).map((token) => {
                  return inputObject[metalType][token] ? (
                    <div className="variant" key={getUUID()}>
                      <div className="label">{translation[`metal-calculator.${metalType}.variant.${token}`]}</div>
                      <div className="value">{formatPrice(inputObject[metalType][token] * getVariantByMetalTypeAndVariantToken(metalType, token).priceInCentsPerGram)}</div>
                      <div className="remove" onClick={removeEntry(metalType, token)}>
                        {translation['metal-calculator.summary.remove.entry']}
                      </div>
                    </div>
                  ) : null
                })}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default MetalCalculator

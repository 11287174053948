import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './components/Home/Home'
import DataProtection from './components/DataProtection/DataProtection'
import Imprint from './components/Imprint/Imprint'
import Login from './components/Auth/Login/Login'
import PasswordLost from './components/Auth/PasswordLost/PasswordLost'
import PasswordReset from './components/Auth/PasswordReset/PasswordReset'
import 'bootstrap/dist/css/bootstrap.min.css'
import Settings from './components/Settings/Settings'
import AdminRouting from './AdminRouting'
import NotFound from './components/NotFound/NotFound'
import PurchasePipeline from './components/PurchasePipeline/PurchasePipeline'
import PurchasePipelineConfirmation from './components/PurchasePipeline/Confirmation/Confirmation'

function App() {
  return (
    <>
      <Settings>
        <Router>
          <Switch>
            {/* LANDINGPAGE */}
            <Route exact={true} path="/" component={Home} />
            {/* PURCHASE PIPELINE */}
            <Route exact={true} path="/ankauf/:uuid" component={PurchasePipeline} />
            {/* PURCHASE PIPELINE CONFIRMATION */}
            <Route exact={true} path="/ankauf-bestaetigen/:uuid" component={PurchasePipelineConfirmation} />
            {/* ADMIN */}
            <Route exact={false} path="/admin" component={AdminRouting} />
            {/* LOGIN */}
            <Route exact={true} path="/login" component={Login} />
            {/* PASSWORD LOST */}
            <Route exact={true} path="/password-lost" component={PasswordLost} />
            {/* PASSWORD LOST */}
            <Route exact={true} path="/password-lost/:code" component={PasswordReset} />
            {/* IMPRINT */}
            <Route exact={true} path="/imprint" component={Imprint} />
            {/* DATA PROTECTION */}
            <Route exact={true} path="/data-protection" component={DataProtection} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Settings>
    </>
  )
}

export default App

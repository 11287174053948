import { backendDomain, presignedUploadURLEndpoint, presignedDownloadURLEndpoint } from './utility'
import axios from 'axios'

export const uploadTypes = {
  OFFER: 'OFFER',
  UNPACKINGVIDEO: 'UNPACKINGVIDEO',
  SHIPMENT_LABEL: 'SHIPMENT_LABEL',
}

const fetch = async (uriSuffix, body = {}, overwriteURL = false) => {
  try {
    if (!uriSuffix) return false
    const result = await axios.post(overwriteURL ? uriSuffix : `${backendDomain}${uriSuffix}`, body)
    return result.data
  } catch (err) {
    return false
  }
}

export const fetchGet = async (uriSuffix, overwriteURL = false, headers = {}) => {
  try {
    if (!uriSuffix) return false
    const result = await axios.get(overwriteURL ? uriSuffix : `${backendDomain}${uriSuffix}`, headers)
    return result
  } catch (err) {
    return false
  }
}

const fileUploadS3Core = async (token, file, type, privacyMode, progressCallback) => {
  const fileMeta = {
    name: file.name,
    type: file.type,
    size: file.size,
  }
  const { status, presignedURL, fileURL, key } = await fetch(presignedUploadURLEndpoint, {
    token,
    fileMeta,
    type,
    privacyMode,
  })
  if (status && presignedURL && (fileURL || key)) {
    const uploadResult = await axios.put(presignedURL, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: (e) => {
        if (typeof progressCallback !== 'function') return
        const progress = parseInt(Math.round((e.loaded / e.total) * 100))
        progressCallback(progress)
      },
    })
    return uploadResult.status === 200 ? fileURL || key : false
  } else {
    return false
  }
}

export const fileUploadS3Public = (token, file, type, progressCallback) => {
  return fileUploadS3Core(token, file, type, 'public', progressCallback)
}

export const fileUploadS3Private = async (token, file, type, progressCallback) => {
  return fileUploadS3Core(token, file, type, 'private', progressCallback)
}

export const fileUploadToBackend = async (token, uriSuffix, file, progressCallback) => {
  const formData = new FormData()
  formData.append('file', file)
  const uploadResult = await axios.post(`${backendDomain}${uriSuffix}?token=${token}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (e) => {
      if (typeof progressCallback !== 'function') return
      const progress = parseInt(Math.round((e.loaded / e.total) * 100))
      progressCallback(progress)
    },
  })
  return uploadResult.status === 200
}

export const getS3DownloadURL = async (token, s3Key, filename) => {
  const { status, url } = await fetch(presignedDownloadURLEndpoint, {
    token,
    s3Key,
    filename,
  })
  if (!status || !url) return false
  return url
}

export default fetch

const translations = {
  'header.purchase-portal-label': 'Ankaufsportal',
  'header.navigation.home': 'Zurück',

  'home.purchase-pipeline.start.success': 'Wir haben Ihnen soeben eine E-Mail zur Bestätigung Ihrer Anfrage geschickt. Es kann bis zu 10 Minuten dauert, bis Sie diese E-Mail erhalten.',
  'home.purchase-pipeline.start.failed': 'Bei der Verarbeitung Ihrer Anfrage ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.',

  'metal.gold': 'Gold',
  'metal.silver': 'Silber',
  'metal.platinum': 'Platin',
  'metal.palladium': 'Palladium',

  'metal-calculator.data-loading': 'Preisdaten werden geladen...',

  'metal-calculator.gold.variant.999': '999er Feingold',
  'metal-calculator.gold.variant.900': '900er Gold',
  'metal-calculator.gold.variant.750': '750er Gold',
  'metal-calculator.gold.variant.585': '585er Gold',
  'metal-calculator.gold.variant.333': '333er Gold',
  'metal-calculator.gold.variant.t750': 'Zahngold (gelb AU 750)',
  'metal-calculator.gold.variant.t600': 'Zahngold (gelb AU 600)',

  'metal-calculator.silver.variant.999': '999er Silber',
  'metal-calculator.silver.variant.925': '925er Silber',
  'metal-calculator.silver.variant.900': '900er Silber',
  'metal-calculator.silver.variant.835': '835er Silber',
  'metal-calculator.silver.variant.800': '800er Silber',
  'metal-calculator.silver.variant.700': '700er Silber',
  'metal-calculator.silver.variant.625': '625er Silber',

  'metal-calculator.platinum.variant.999': '999er Platin',
  'metal-calculator.platinum.variant.950': '950er Platin',
  'metal-calculator.platinum.variant.750': '750er Platin',

  'metal-calculator.palladium.variant.999': '999er Palladium',
  'metal-calculator.palladium.variant.950': '950er Palladium',
  'metal-calculator.palladium.variant.500': '500er Palladium',

  'metal-calculator.gram.amount-suffix': ' in Gramm',
  'metal-calculator.send-request': 'Anfrage starten',
  'metal-calculator.gram.price.suffix': ' je Gramm',
  'metal-calculator.gram.label-suffix': ' Gramm',
  'metal-calculator.refreshed-on-label': 'Aktualisiert am: ',

  'metal-calculator.fineness-selector.headline': 'Wählen Sie bitte die Feinheit Ihres Edelmetalls:',
  'metal-calculator.amount-input.current-price.label': 'Aktueller Kurs pro Gramm:',
  'metal-calculator.input-wrapper.label': 'Ihre Menge in Gramm:',
  'metal-calculator.result.label': 'Gesamtwert:',

  'meta-calculator.summary.headline': 'Ihre Zusammenfassung:',

  'metal-calculator.type-selector.label': 'Wählen Sie ein Edelmetall aus.',

  'metal-calculator.summary.remove.entry': 'Löschen',

  'personal-form.headline': 'Persönliche Daten',
  'personal-form.submit': 'Anfrage absenden',
  'personal-form.back': 'Zurück',
  'personal-form.firstname': 'Vorname',
  'personal-form.lastname': 'Nachname',
  'personal-form.mail': 'E-Mail Adresse',
  'personal-form.street': 'Straße',
  'personal-form.housenumber': 'Hausnummer',
  'personal-form.postcode': 'Postleitzahl',
  'personal-form.city': 'Stadt',
  'personal-form.addressNote': 'Adresszusatz',

  'purchase-pipeline.loading.failed': 'Ihre Inhalten konnten nicht geladen werden, bitte versuchen Sie es später erneut.',
  'purchase-pipeline.confirmation.loading': 'Ihre Anfrage wird bestätigt... Bitte warten Sie einen Moment',
  'purchase-pipeline.confirmation.failed': 'Ihre Anfrage konnte leider nicht bestätigt werden.',

  'purchase-pipeline.entry.type.start.title': 'Anfrage',
  'purchase-pipeline.entry.type.start.subtitle': 'Eingetragene Positionen.',
  'purchase-pipeline.entry.type.start.hint': 'Ihr Angebot wird aufgrund der aktuellen Marktpreise berechnet, unabhängig des Preises zum Zeitpunkt Ihrer Anfrage.',
  'purchase-pipeline.entry.type.start.price.hint': ' zum Zeitpunkt der Anfrage',

  'purchase-pipeline.entry.type.shipment.title': 'Versand',
  'purchase-pipeline.entry.type.shipment.subtitle': 'Wählen Sie als nächstes Ihre gewünschte Versandmethode:',
  'purchase-pipeline.entry.type.shipment.methods.standard': 'Standartversand',
  'purchase-pipeline.entry.type.shipment.methods.standard.hint': 'Versichert bis zu einem Wert von 500 €.',
  'purchase-pipeline.entry.type.shipment.methods.express': 'Expressversand',
  'purchase-pipeline.entry.type.shipment.methods.express.hint': 'Versichert bis zu einem Wert von 10.000 €.',
  'purchase-pipeline.entry.type.shipment.methods.confirm-cta': 'Versandart bestätigen',
  'purchase-pipeline.entry.type.shipment.submit-error': 'Bei der Verarbeitung Ihrer Versandart ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
  'purchase-pipeline.entry.type.shipment.cover-label.label': 'In der Zwischenzeit können Sie bereits das, für den Versand benötigte, Begleitschreiben herunterladen und ausfüllen.',
  'purchase-pipeline.entry.type.shipment.cover-label-secondary.label': 'Begleitschreiben:',
  'purchase-pipeline.entry.type.shipment.label.download-label': 'Versandlabel herunterladen',
  'purchase-pipeline.entry.type.shipment.cover-label.download-cta': 'Begleitschreiben herunterladen',
  'purchase-pipeline.entry.type.shipment.action-label': 'Wir erstellen im nächsten Schritt ein kostenloses Versandlabel für Sie, welches Sie via E-Mail erhalten werden.',
  'purchase-pipeline.entry.type.shipment.action-label-secondary': 'Laden Sie im Folgenden Ihr Versandlaben herunter:',
  'purchase-pipeline.entry.type.shipment.download-cta': 'Versandlabel bestellen',
  'purchase-pipeline.entry.type.shipment.action-completed-hint':
    'Warten Sie bitte mit dem Versand bis wir Ihnen Ihr Versandlabel zugeschickt haben. Legen Sie für den Versand Ihre Edelmetalle UND DAS BEGLEITSCHREIBEN in das Packet und kleben Sie das Versandlabel auf das Paket.',
  'purchase-pipeline.entry.type.shipment.action-completed-hint-secondary':
    'Legen Sie für den Versand Ihre Edelmetalle UND DAS BEGLEITSCHREIBEN in das Packet und kleben Sie das Versandlabel auf das Paket.',

  'purchase-pipeline.entry.type.review.title': 'Prüfung',
  'purchase-pipeline.entry.type.review.label.completed.false': 'Wir haben Ihre Sendung erhalten und überprüfen nun Ihre Edelmetalle, im Anschluss erhalten Sie ein Angebot.',
  'purchase-pipeline.entry.type.review.label.completed.true': 'Wir haben die Prüfung Ihrer Edelmetalle abgeschlossen.',

  'purchase-pipeline.entry.type.offer.title': 'Angebot',
  'purchase-pipeline.entry.type.offer.download.label': 'Angebot herunterladen',
  'purchase-pipeline.entry.type.offer.reply.accept': 'Akzeptieren',
  'purchase-pipeline.entry.type.offer.reply.iban': 'Empfänger-IBAN eingeben',
  'purchase-pipeline.entry.type.offer.reply.decline': 'Ablehnen',
  'purchase-pipeline.entry.type.offer.reply.send': 'Bestätigen',
  'purchase-pipeline.entry.type.offer.reply.send-error': 'Ein technischer Fehler ist aufgetreten.',
  'purchase-pipeline.entry.type.offer.reply.received.accepted': 'Sie haben unser Angebot angenommen.',
  'purchase-pipeline.entry.type.offer.reply.received.declined': 'Sie haben unser Angebot abgelehnt. Wir senden Ihnen Ihre Edelmetalle schnellstmöglich zurück.',

  'purchase-pipeline.entry.type.payment.title': 'Zahlung',
  'purchase-pipeline.entry.type.payment.done': 'Wir haben Ihnen den genannten Betrag lauf Angebot überwiesen. Das Geld sollte innerhalb der nächsten 2 - 3 Werktage auf Ihrem Konto eingehen.',
  'purchase-pipeline.entry.type.payment.pending': 'Sobald wir Ihnen den genannten Betrag überwiesen haben, erhalten Sie eine Mitteilung.',

  'purchase-pipeline.entry.type.completed.title': 'Abschluss',
  'purchase-pipeline.entry.type.completed.message': 'Ankaufprozess abgeschlossen.',

  'login.headline': 'Login',
  'login.mail': 'E-Mail Adresse',
  'login.password': 'Passwort',
  'login.button': 'Einloggen',

  'layout-admin.navigation.purchase-pipeline.list.active': 'Aktiv',
  'layout-admin.navigation.purchase-pipeline.list.completed': 'Abgeschlossen',
  'layout-admin.logo-label': 'Admin-Dashboard',
  'layout-dashboard.logout': 'Logout',

  'admin.purchase-pipelinst-list.headline': 'Liste der Ankaufprozesse:',
  'admin.purchase-pipelinst-list.active.no-results': 'Keine aktiven Ankaufsprozesse.',
  'admin.purchase-pipelinst-list.completed.no-results': 'Keine abgeschlossenen Ankaufsprozesse.',
  'admin.purchase-pipelinst-list.loading.failed': 'Ein Fehler beim Laden der Liste ist aufgetreten.',
  'admin.purchase-pipelinst-list.item.latest-status.label': 'Aktueller Status: ',
  'admin.purchase-pipelinst-list.item.view.label': 'Einsehen',
  'admin.purchase-pipeline-list.item.date-started.label': 'Gestarted am: ',
  'admin.purchase-pipeline-list.item.date-completed.label': 'Beended am: ',

  'admin.purchase-pipelinst-detail.headline': 'Ankaufprozess - Detailansicht:',
  'admin.purchase-pipeline-detail.unpacking-video.headline': 'Wareneingangsvideo hochladen:',
  'admin.purchase-pipeline-detail.unpacking-video.upload-cta': 'Video hochladen',
  'admin.purchase-pipeline-detail.unpacking-video.upload-cta-overwrite': 'Video überschreiben',
  'admin.purchase-pipeline-detail.unpacking-video.upload-failed': 'Hochladen des Video is fehlgeschlagen.',
  'admin.purchase-pipeline-detail.unpacking-video.download-cta': 'Video herunterladen',
  'admin.purchase-pipelinst-detail.loading.failed': 'Die Detailansicht konnte nicht geladen werden.',
  'admin.purchase-pipelinst-detail.item.start.title': 'Daten:',

  'admin.purchase-pipelinst-detail.item.shipment.title': 'Versand:',
  'admin.purchase-pipelinst-detail.item.shipment.not-selected': 'Der Kunde hat noch keine Versandart gewählt.',
  'admin.purchase-pipelinst-detail.item.shipment.selected-prefix': 'Der Kunde hat folgende Versandart gewählt: ',
  'admin.purchase-pipelinst-detail.item.shipment.upload-label': 'Versandlabel hochladen',
  'admin.purchase-pipelinst-detail.item.shipment.mark-as-received': 'Wir haben das Paket erhalten',
  'admin.purchase-pipelinst-detail.item.shipment.mark-as-received.error': 'Ein technischer Fehler ist aufgetreten.',

  'admin.purchase-pipelinst-detail.item.review.title': 'Prüfung:',
  'admin.purchase-pipelinst-detail.item.review.upload-offer-label': 'Angebot hochladen',
  'admin.purchase-pipelinst-detail.item.review.upload-offer.success': 'Das Angebot wurde erfolgreich hochgeladen und an den Kunden weitergeleitet.',
  'admin.purchase-pipelinst-detail.item.review.upload-offer.failed': 'Ein techischer Fehler ist aufgetreten.',

  'admin.purchase-pipelinst-detail.item.offer.title': 'Angebot',
  'admin.purchase-pipelinst-detail.item.offer.label':
    'Hier kann das Angebot, welches der Kunde bereits bekommen hat, nochmals eingesehen werden. Der Kunde kann das Angebot nun annehmen oder ablehnen.',
  'admin.purchase-pipelinst-detail.item.offer.download': 'Angebot herunterladen',
  'admin.purchase-pipelinst-detail.item.offer.accepted': 'Der Kunde hat das Angebot angenommen.',
  'admin.purchase-pipelinst-detail.item.offer.declined': 'Der Kunde hat das Angebot abgelehnt.',

  'admin.purchase-pipelinst-detail.item.payment.title': 'Zahlung',
  'admin.purchase-pipelinst-detail.item.payment.iban-label': 'IBAN des Empfängers: ',
  'admin.purchase-pipelinst-detail.item.payment.fulfilled-cta': 'Zahlung erledigt',
  'admin.purchase-pipelinst-detail.item.payment.fulfilled': 'Der Betrag wurde an den Kunden überwiesen.',
  'admin.purchase-pipelinst-detail.item.payment.fulfilled-cta.failed': 'Ein techischer Fehler ist aufgetreten.',

  'admin.purchase-pipelinst-detail.item.completed.title': 'Prozess abgeschlossen',

  'download.failed': 'Download fehlgeschlagen.',

  imprint: 'Impressum',
  gtc: 'AGB',
  'data-protection': 'Datenschutz',

  'dropdown.empty-selection': '-Wählen-',
}

export const langKey = 'DE'

export default translations

import Layout from '../Layout/Layout'
import './Home.scss'
import Container from 'react-bootstrap/esm/Container'
import MetalCalculator from './MetalCalculator/MetalCalculator'
import PersonalForm from './PersonalForm/PersonalForm'
import { useState } from 'react'
import fetch from '../../fetch'
import { useSelector } from 'react-redux'
import Header from '../Layout/Header/Header'

const Home = () => {
  const [metalCalculatorData, setMetalCalculatorData] = useState(null)
  const [personalData, setPersonalData] = useState({})
  const [message, setMessage] = useState(null)
  const translation = useSelector((state) => state.translation)

  const pushMetalData = (data) => {
    setMetalCalculatorData(data)
  }

  const hasMetalData = () => !!metalCalculatorData

  const pushPersonalData = async (data) => {
    setPersonalData(data)
    setMessage(null)
    if (hasMetalData()) {
      const { status } = await fetch('/purchase-pipeline/start', { personalData: data, metalCalculatorData })
      if (status) {
        setMessage(translation['home.purchase-pipeline.start.success'])
      } else {
        setMessage(translation['home.purchase-pipeline.start.failed'])
      }
    }
  }

  return (
    <div id="home">
      <Layout>
        <div className="content-wrapper">
          <Header />
          <Container>
            <div className="card-content">
              {!message && <MetalCalculator pushData={pushMetalData} data={metalCalculatorData} />}
              {!message && hasMetalData() && <PersonalForm data={personalData} pushData={pushPersonalData} />}
              {message && <div className="message">{message}</div>}
            </div>
          </Container>
        </div>
      </Layout>
    </div>
  )
}

export default Home
